const sites = []

export default {
    getSites: function () {
        return [...sites].flat()
    },
    addSites: function (newSites) {
        sites.push(newSites)
        return true
    },
}
