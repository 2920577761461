<template>
  <v-container
    id="register-view"
    fluid
    class="d-flex justify-center align-center"
  >
    <v-container
      id="register-view"
      fluid
      class="d-flex flex-column align-center"
    >
      <v-card
        width="500px"
      >
        <v-form
          id="form"
          ref="form"
          v-model="isFormValid"
          onsubmit="return false;"
          @submit="showConfirmDialog = true"
        >
          <v-card-text>
            <v-container class="d-flex justify-center">
              <v-card-title
                class="text-h2 font-weight-bold grey--text justify-center pa-0"
              >
                Register New User
              </v-card-title>
            </v-container>
            <v-sheet
              width="80%"
              style="margin-right: 10%; margin-left: 10%"
            >
              <v-container
                v-if="loadingSites"
                class="d-flex justify-center align-center"
              >
                <v-progress-circular
                  indeterminate
                  color="green"
                  class="mr-6"
                />
                <span> Loading Sites...</span>
              </v-container>
              <div v-if="sites.length > 0">
                <div class="mb-3">
                  Add a user to one or more of the following sites:
                </div>
                <v-checkbox
                  v-model="allSitesOption"
                  class="my-0"
                  label="All Sites"
                  @change="changeAllSitesSelection"
                />
                <template v-for="site in sites">
                  <v-checkbox
                    :key="site.name"
                    v-model="selectedSites"
                    class="my-0"
                    :label="site.name"
                    :value="site"
                    @change="onChangeSingleSite"
                  />
                </template>
              </div>
              <v-expand-transition>
                <v-select
                  v-if="selectedSites.length > 0"
                  v-model="roleSelected"
                  class="mt-3"
                  :items="userRoles"
                  label="User Role"
                  dense
                  outlined
                  required
                  :rules="[rules.required]"
                />
              </v-expand-transition>
              <v-expand-transition>
                <v-container
                  v-if="siteAndRoleSelected"
                  class="pt-0"
                >
                  <v-text-field
                    v-model="firstName"
                    label="First Name"
                    :rules="[rules.required]"
                  />
                  <v-text-field
                    v-model="lastName"
                    label="Last Name"
                    :rules="[rules.required]"
                  />
                  <v-text-field
                    id="email"
                    :key="uniqueArray[uniqueArray.length-1]"
                    v-model="email"
                    label="Email"
                    :rules="[rules.required, rules.email, rules.unique]"
                  />
                </v-container>
              </v-expand-transition>
            </v-sheet>
          </v-card-text>
          <v-card-actions class="justify-center pb-10">
            <v-btn
              v-if="!loadingSites"
              :disabled="!isFormValid || loadingSites || selectedSites.length === 0"
              color="green darken-1"
              text
              width="80%"
              height="56px"
              style="font-size: 14px"
              type="submit"
            >
              Add user
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-alert
        v-if="showStatusMessage"
        :type="statusMessageType"
        width="500px"
        class="mt-2"
      >
        {{ statusMessage }}
      </v-alert>
    </v-container>
    <v-dialog
      v-model="showConfirmDialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="d-flex justify-center text-h5 pt-5">
          Please confirm the following information:
        </v-card-title>
        <v-container style="width: 65%; margin-left: 130px;">
          <v-simple-table>
            <tbody>
              <tr>
                <td>
                  Site(s)
                </td>
                <td>{{ selectedSites.map(site => site.name).join(', ') }}</td>
              </tr>
              <tr>
                <td>Role</td>
                <td>{{ roleSelected }}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{{ `${firstName} ${lastName}` }}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{{ email }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-container>
        <v-card-actions
          class="d-flex justify-center pb-5"
        >
          <v-btn
            color="green darken-1"
            style="width: 30%; padding-right: 20px;"
            text
            :loading="submitLoading"
            :disabled="submitLoading"
            @click="submit"
          >
            Confirm
          </v-btn>
          <v-btn
            color="grey darken-1"
            style="width: 30%"
            text
            :disabled="submitLoading"
            @click="showConfirmDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showSubmitDialog"
      max-width="600"
      @click:outside="onCloseSubmitDialog"
    >
      <v-alert
        :type="submitDialogType"
        class="mb-0"
      >
        {{ submitDialogMessage }}
      </v-alert>
    </v-dialog>
  </v-container>
</template>

<script>
  import sitesApi from '@/api/sites.js'
  import sitesHolder from '@/util/sites-holder.js'
  import registerApi from '@/api/register.js'
  import { UserRoles } from '@/util/enums'
  import common from '@/api/common.js'

  export default {
    name: 'Register',
    data: () => ({
      sites: [],
      loadingSites: true,
      userRoles: Object.keys(UserRoles),
      firstName: '',
      lastName: '',
      email: '',
      siteSelected: '',
      roleSelected: '',
      isFormValid: false,
      showStatusMessage: false,
      statusMessage: '',
      statusMessageType: 'error',
      noSitesMessage: 'There was an error loading the site data. Please try again later.',
      showConfirmDialog: false,
      submitLoading: false,
      showSubmitDialog: false,
      submitDialogType: 'success',
      submitDialogMessage: '',
      submitSuccessMessage: 'New user registered successfully!',
      submitErrorMessage: 'Unable to process your request at this time. Please try again later.',
      uniqueArray: [],

      selectedSites: [],
      allSitesOption: false,
    }),
    computed: {
      siteAndRoleSelected () {
        return this.selectedSites.length > 0 && this.roleSelected
      },
      rules () {
        const array = this.uniqueArray
        const rules = {}
        rules.required = (value) => !!value || 'Required.'
        rules.email = (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
        rules.unique = (value) => !array.includes(value) || 'A user with this email already exists'
        return rules
      },
      siteOptions () {
        return ['All Sites'].concat(this.sites.map(site => site.name))
      },
    },
    watch: {
      rules: function () {
        // need a slight delay so form validates after being updated with new rules, this causes error to be highlighted
        setTimeout(() => {
          this.$refs.form.validate()
        }, 10)
      },
    },
    created () {
      const cachedSites = sitesHolder.getSites()
      if (cachedSites > 0) {
        this.sites = cachedSites.map(siteResponse => (
          { name: siteResponse.site.name, id: siteResponse.site.id }
        ))
        this.loadingSites = false
      } else {
        this.getSitesForUser()
      }
    },
    methods: {
      submit () {
        this.submitLoading = true
        let skipFinallyBlock = false
        if (this.isFormValid) {
          const siteIds = this.selectedSites.map(site => site.id)
          registerApi
            .signup(this.email, this.firstName, this.lastName, UserRoles[this.roleSelected], siteIds)
            .then((response) => {
              console.log('response', response)
              if (response.status === 200) {
                this.submitLoading = false
                this.showConfirmDialog = false
                this.showSubmitDialog = true
                this.submitDialogType = 'success'
                this.submitDialogMessage = this.submitSuccessMessage
              }
            })
            .catch(async (error) => {
              console.log('error', error)
              if (await common.handleBadCall(error, this.$router) === true) {
                skipFinallyBlock = true
                this.getSitesForUser()
              } else {
                if (error.response.status === 409) {
                  this.showConfirmDialog = false
                  this.submitLoading = false
                  this.uniqueArray.push(this.email)
                } else {
                  this.submitLoading = false
                  this.showConfirmDialog = false
                  this.showSubmitDialog = true
                  this.submitDialogType = 'error'
                  this.submitDialogMessage = this.submitErrorMessage
                }
              }
            }).finally(() => {
              if (!skipFinallyBlock) {
                this.submitLoading = false
                this.showConfirmDialog = false
              }
            })
        }
      },
      getSitesForUser () {
        sitesApi.getSitesForUser()
          .then(response => {
            console.log(response)
            this.sites = response.data.map(siteResponse => (
              { name: siteResponse.site.name, id: siteResponse.site.id }
            ))
            this.loadingSites = false

            // add sites to holder whenever it is the first time a user requests them
            sitesHolder.addSites(response.data.map(siteResponse =>
              siteResponse.site,
            ))
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getSitesForUser()
            } else {
              this.loadingSites = false
              this.showStatusMessage = true
              this.statusMessageType = 'error'
              this.statusMessage = this.noSitesMessage
            }
          })
      },
      onCloseSubmitDialog () {
        this.showSubmitDialog = false
        if (this.submitDialogType === 'success') {
          this.$router.push({ path: '/admin/' })
        }
      },

      changeAllSitesSelection (value) {
        if (value === true) {
          this.selectedSites = [...this.sites]
        } else {
          this.selectedSites = []
        }
      },
      onChangeSingleSite (value) {
        this.allSitesOption = this.selectedSites.length === this.sites.length
      },
    },
  }
</script>
