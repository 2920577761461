import axios from 'axios'
import common from '@/api/common.js'

const api = axios.create()

export default {
  getSitesForUser: function () {
    /*
    api.interceptors.request.use(request => {
      console.log('Starting Request', JSON.stringify(request, null, 2))
      return request
    })
    */

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/sites/getForUser`,
    })
  },

  getChildrenOfSite: function (siteId) {
    /*
    api.interceptors.request.use(request => {
      console.log('Starting Request', JSON.stringify(request, null, 2))
      return request
    })
    */

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/sites/getChildren`,
      data: {
        siteId: siteId,
      },
    })
  },

  getSiteById: function (siteId) {
    api.interceptors.request.use(request => {
      console.log('Starting Request', JSON.stringify(request, null, 2))
      return request
    })

    const headers = common.createHeaders()

    return api({
      method: 'get',
      headers: headers,
      url: `${common.getUrlPrefix()}/sites/get/` + siteId,
    })
  },

  getDataForSiteLayer: function (siteId, siteLayerId) {
    console.log('getDataForSiteLayer()', siteId, siteLayerId)

    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/sites/layer/get`,
      data: {
        siteId,
        siteLayerId,
      },
    })
  },
}
